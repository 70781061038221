import React, { useRef } from 'react';
import { EmailFormSection } from 'views/single-resource/components/email-form-section';
import { ImageForm } from 'views/single-resource/medical-data-protection/components/image-form';
import { InfoSection } from 'views/single-resource/medical-data-protection/components/info-section';
import { MainSection } from 'views/single-resource/medical-data-protection/components/main-section';
import { ROOT_PATH } from 'views/single-resource/medical-data-protection/constants';
import { RequestSection } from 'modules/request-section';

import { medicalDataProtectionDialogContent } from './constants';

export const MedicalDataProtectionView = () => {
    const ref = useRef();
    const scrollTo = () => {
        ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };

    return (
        <>
            <MainSection onClick={() => scrollTo()} />
            <InfoSection />
            <EmailFormSection
                ref={ref}
                rootPath={ROOT_PATH}
                image={<ImageForm />}
                formId="medical-data-protection-form"
                customDialogContent={medicalDataProtectionDialogContent}
                isEbookDownloadable
            />
            <RequestSection />
        </>
    );
};
