import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const SImage = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
`;

export const ImageForm = () => {
    const image = useStaticQuery(graphql`
        {
            background: file(
                relativePath: {
                    eq: "medical-data-protection/keep-medical-data.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: FULL_WIDTH
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <SImage
            placeholder="blurred"
            image={image?.background?.childImageSharp?.gatsbyImageData}
            alt="White Page Snapshot"
        />
    );
};
