import React from 'react';
import { MedicalDataProtectionView } from 'views/single-resource/medical-data-protection';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/resources/medical-data-protection.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const MedicalDataProtectionPage = ({ location }) => {
    return (
        <Layout location={location} messages={messages}>
            <MedicalDataProtectionView />
        </Layout>
    );
};

MedicalDataProtectionPage.propTypes = {
    location: object.isRequired,
};

export default MedicalDataProtectionPage;

export const Head = () => (
    <SEO tags={TAGS.MEDICAL_DATA_PROTECTION_LANDING_PAGE} />
);
