import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { PERCENTAGE_INFO } from 'views/single-resource/medical-data-protection/constants';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    max-width: 54.75rem;
    margin: 0 auto 3.75rem auto;
`;

const SSpan = styled.span`
    color: var(--primary-color);
`;

const SWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;

    ${CONSTANTS.MEDIA.max768`
        grid-template-columns: 1fr;
    `}
`;

const SCircleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SCircle = styled.div`
    border: 2px solid var(--primary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11.25rem;
    height: 11.25rem;
    margin-bottom: 1.75rem;
`;

const SPercentage = styled.span`
    font-weight: 700;
    font-size: 3.625rem;
    line-height: 68px;
`;

const SDescription = styled.p`
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 27px;
    text-align: center;
    max-width: 21rem;

    ${CONSTANTS.MEDIA.max768`
        margin-bottom: 3rem;
    `}
`;

export const InfoSection = () => {
    const { formatMessage } = useIntl();

    return (
        <Container>
            <SHeaderSecond>
                {formatMessage(
                    {
                        id: 'medical-data-protection.twoInThreeHealthcare',
                    },
                    {
                        r: (chunks) => <SSpan>{chunks[0]}</SSpan>,
                    },
                )}
            </SHeaderSecond>
            <SWrapper>
                {PERCENTAGE_INFO.map(({ value, description }, index) => {
                    return (
                        <SCircleWrapper key={index}>
                            <SCircle>
                                <SPercentage>{value}</SPercentage>
                            </SCircle>
                            <SDescription>
                                <FormattedMessage id={description} />
                            </SDescription>
                        </SCircleWrapper>
                    );
                })}
            </SWrapper>
        </Container>
    );
};
